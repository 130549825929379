import React, { useMemo, useState } from 'react'
import { formatDate } from '@broker-crm-utils'
import { Box } from '@mui/material'
import { format } from 'date-fns'
import { Field, Form, FormSpy } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { differenceInObjects, getFormMetaErrors, useFormatMessage } from '@acre/utils'
import {
  formatAsCurrency,
  GetMortgageProductDocument,
  MortgageStatus,
  useGetMortgageProductQuery,
  useGetOrganisationQuery,
  useUpdateMortgageMutation,
} from '@acre/graphql'
import {
  BUTTON_PRIMARY,
  ColouredItemCard,
  ColourId,
  COMPACT_LEFT_ALIGNED,
  CurrencyInput,
  H2,
  Modal,
  ModalFooter,
  YesNoRadioGroup,
} from '@acre/design-system'
import { DateFieldFF } from '@acre/forms-ui'

import { messagePrefix, validate } from './CompleteTransitionTask.helpers'

type Props = {
  caseId: string
  onSubmit?: () => any
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const MortgageDetailsModal = ({ caseId, onSubmit, isModalOpen, setIsModalOpen }: Props) => {
  const intl = useIntl()
  const formatMessage = useFormatMessage()

  const [areMortgageDetailsCorrect, setAreMortgageDetailsCorrect] = useState<boolean | null>(null)
  const handleClose = () => setIsModalOpen(false)

  const [updateMortgage, { loading: loadingUpdateMortgage }] = useUpdateMortgageMutation({
    refetchQueries: [
      {
        query: GetMortgageProductDocument,
        variables: {
          id: caseId,
        },
      },
    ],

    awaitRefetchQueries: true,
  })

  const { data } = useGetMortgageProductQuery({
    variables: {
      id: caseId,
    },
  })

  const selectedMortgage = data?.case.details.mortgages?.find(
    (mortgage) => mortgage.status === MortgageStatus.StatusSelected,
  )
  const today = useMemo(() => format(new Date(), 'yyyy-MM-dd'), [])

  const { mortgage_product, mortgage_amount, mortgage_start_date = today, mortgage_id } = selectedMortgage || {}

  const { lender_reference, product_name } = mortgage_product || {}

  const { data: lenderData } = useGetOrganisationQuery({
    variables: { id: lender_reference! },
    skip: !lender_reference,
  })

  const lenderName = lenderData?.organisation && lenderData.organisation.name

  const mortgageDetailsFields = [
    {
      id: 'MortgageLenderName',
      label: <FormattedMessage id={`${messagePrefix}.lender`} />,
      value: lenderName,
    },
    {
      id: 'MortgageAmount',
      label: <FormattedMessage id={`${messagePrefix}.mortgage`} />,
      value: mortgage_amount && formatAsCurrency(mortgage_amount),
    },
    {
      id: 'MortgageProductName',
      label: <FormattedMessage id={`${messagePrefix}.product`} />,
      value: product_name,
    },
    {
      id: 'MortgageStartDate',
      label: <FormattedMessage id={`${messagePrefix}.startDate`} />,
      value: mortgage_start_date && formatDate(mortgage_start_date),
    },
  ]

  const handleOnSubmit = async (values: typeof initialValues) => {
    const differences = differenceInObjects(values, initialValues)

    let input = { ...differences }

    if (differences.mortgage_amount) {
      input = { ...differences, repayment_amount: values.mortgage_amount }
    }

    if (mortgage_id) {
      await updateMortgage({
        variables: {
          id: mortgage_id!,
          input,
        },
      })
    }

    onSubmit && onSubmit()
  }

  const initialValues = useMemo(
    () => ({ mortgage_amount, mortgage_start_date }),
    [mortgage_amount, mortgage_start_date],
  )

  const validation = useMemo(() => validate(initialValues), [initialValues])

  return (
    <>
      <Form onSubmit={handleOnSubmit} initialValues={initialValues} validate={validation}>
        {({ handleSubmit }) => (
          <Modal
            className="modal-reduced-padding"
            id="ConfirmCaseTransitionModal"
            open={isModalOpen}
            onClose={handleClose}
            header={{
              left: (
                <H2 styledAs="h5">
                  <FormattedMessage id={`${messagePrefix}.confirmMortgageDetails`} />
                </H2>
              ),
            }}
            footer={
              <MortgageDetailsFooter
                isLoading={loadingUpdateMortgage}
                onSubmit={onSubmit}
                disabled={areMortgageDetailsCorrect === null}
                onClose={handleClose}
              />
            }
          >
            <form onSubmit={handleSubmit}>
              <Box mb={2}>
                <ColouredItemCard
                  title={formatMessage(`${messagePrefix}.mortgageDetails`)}
                  colourID={ColourId.AcreBlue}
                  variant={COMPACT_LEFT_ALIGNED}
                  id="MortgageDetailsCard"
                  data={mortgageDetailsFields}
                  defaultValue={formatMessage('generic.noInformation')}
                  align="left"
                />
              </Box>
              <Box mb={2}>
                <YesNoRadioGroup
                  value={areMortgageDetailsCorrect}
                  onChange={setAreMortgageDetailsCorrect}
                  id="ConfirmMortgageDetailsCorrect"
                  name="ConfirmMortgageDetailsCorrect"
                  variant={COMPACT_LEFT_ALIGNED}
                  label={formatMessage(`${messagePrefix}.confirmMortgageDetailsCorrect`)}
                />
              </Box>
              {areMortgageDetailsCorrect === false && (
                <>
                  <Box mb={2}>
                    <Field name="mortgage_amount">
                      {({ input, meta }) => {
                        const label = formatMessage(`${messagePrefix}.mortgageAmount`)
                        return (
                          <CurrencyInput
                            {...input}
                            id="MortgageAmountInput"
                            label={label}
                            variant={COMPACT_LEFT_ALIGNED}
                            {...getFormMetaErrors({
                              meta,
                              intl,
                              messageInsert: { label },
                            })}
                          />
                        )
                      }}
                    </Field>
                  </Box>
                  <Box mb={2}>
                    <DateFieldFF
                      name="mortgage_start_date"
                      label={<FormattedMessage id={`${messagePrefix}.startDate`} />}
                    />
                  </Box>
                </>
              )}
            </form>
          </Modal>
        )}
      </Form>
    </>
  )
}
export default MortgageDetailsModal

const MortgageDetailsFooter = ({
  isLoading,
  onSubmit,
  onClose,
  disabled,
}: {
  isLoading: boolean
  onSubmit?: () => void
  onClose: () => void
  disabled: boolean
}) => {
  return (
    <FormSpy subscription={{ pristine: true, hasValidationErrors: true }}>
      {({ form, pristine, hasValidationErrors }) => (
        <ModalFooter
          secondaryButton={{ onClick: () => onClose(), id: 'CancelReviewFinalCaseFees', isDisabled: false }}
          primaryButton={{
            id: 'ReviewFinalCaseFees',
            style: BUTTON_PRIMARY,
            isDisabled: disabled || hasValidationErrors,
            onClick: async () => {
              pristine ? onSubmit && onSubmit() : await form.submit()
            },
            isLoading: isLoading,
            text: `${messagePrefix}.next`,
          }}
        />
      )}
    </FormSpy>
  )
}
