import React from 'react'
import type { ArrayPath, FieldValues, Path } from 'react-hook-form'

import type { ComponentSchemaPropsMapBase, FormComponentSchema } from '../../types/schema'
import Field from '../Field'
import SchemaFieldArrayComponent, { SchemaFieldArrayComponentProps } from './SchemaFieldArrayComponent'
import type { FieldTypes } from '../../types/types'
import type { AnyObjectSchema } from 'yup'

export type SchemaFormComponentProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues> | ArrayPath<TFieldValues>,
  TComponentPropsMap extends ComponentSchemaPropsMapBase = ComponentSchemaPropsMapBase,
> = FormComponentSchema<TFieldValues, TComponentPropsMap, TName>

const _SchemaFormComponent = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> | ArrayPath<TFieldValues> = Path<TFieldValues>,
>(
  props: SchemaFormComponentProps<TFieldValues, TName>,
) => {
  if (props.type === 'array') {
    return (
      <SchemaFieldArrayComponent
        // Explicitly cast here because I'm pretty sure we can never resolve this type here but it doesn't matter
        {...(props as unknown as SchemaFieldArrayComponentProps<TFieldValues, ArrayPath<TFieldValues>>)}
        name={props.name as ArrayPath<TFieldValues>}
      />
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Field<FieldTypes, any, AnyObjectSchema, TFieldValues> {...props} />
}

const SchemaFormComponent = React.memo(_SchemaFormComponent) as typeof _SchemaFormComponent

export default SchemaFormComponent
