import { omitProperties, useFormatMessage } from '@acre/utils'
import {
  BusinessProtectionBenefitOptions,
  BusinessProtectionPmiProductBenefitItem,
  Client,
  ClientIncome,
  formatAsCurrency,
  Maybe,
  PayoutType,
  ProductBenefit,
  ProductBenefitInput,
  ProductBenefitItem,
  ProductBenefitItemInput,
  ProtectionProduct,
  ProtectionProductBenefitItem,
  ProtectionProductStatus,
  ProtectionProductType,
  SolutionProductBenefit,
  SolutionProductBenefitGroupItem,
  SolutionProductBenefitItem,
  SolutionProductBenefitItemMetLife,
  SolutionProductBenefitItemPmi,
  SolutionProductBenefitItemPrivateMedicalInsurance,
  SolutionProductType,
} from '@acre/graphql'
import { ColourId } from '@acre/design-system'

import {
  coverUnitsMap,
  payoutTypeMapShorthand,
  productTypeMap,
  productTypeMapShorthand,
  underwritingLevelMap,
} from './protectionEnumTranslationMappings'

export const isRecommendedProtectionProduct = (product: ProtectionProduct): boolean => {
  const { details } = product
  const hasSource = details.source !== null && details.source !== undefined
  return hasSource
}

export const isExistingProtectionProduct = (product: ProtectionProduct): boolean => {
  const { details } = product
  const isVisible =
    details.status === ProtectionProductStatus.Current || details.status === ProtectionProductStatus.Cancelled
  return isVisible
}

export const getClientsFromQuotes = (quotes: ProductBenefitItemInput[], clients: Client[]) => {
  const clientIds = quotes.map((quote) => {
    return quote?.client_ids && quote.client_ids.length > 0 ? quote.client_ids : null
  })

  const relevantClientIds = clientIds.filter((clientId) => clientId !== null) as string[][]
  const flattenedRelevantClientIds = relevantClientIds.reduce((acc, clientIds) => {
    acc = [...acc, ...clientIds]
    return acc
  }, [])

  // return Client[] and not ClientVersion[], so extract details from Client
  const relevantClients = clients.filter(({ id }) => flattenedRelevantClientIds.includes(id))

  return relevantClients
}

export const isTermSolution = ({ product_type }: SolutionProductBenefitItem) =>
  product_type === SolutionProductType.ProductTypeTerm

export const isFibSolution = ({ product_type }: SolutionProductBenefitItem) =>
  product_type === SolutionProductType.ProductTypeFamilyIncomeBenefit

export const isWolSolution = ({ product_type }: SolutionProductBenefitItem) =>
  product_type === SolutionProductType.ProductTypeWholeOfLife

export const isIpSolution = ({ product_type }: SolutionProductBenefitItem) =>
  product_type === SolutionProductType.ProductTypeIncomeProtection

export const isTerm = (product_type?: ProtectionProductType | SolutionProductType) =>
  product_type === ProtectionProductType.ProductTypeDecreasingTerm ||
  product_type === ProtectionProductType.ProductTypeLevelTerm ||
  product_type === ProtectionProductType.ProductTypeTerm ||
  product_type === SolutionProductType.ProductTypeDecreasingTerm ||
  product_type === SolutionProductType.ProductTypeLevelTerm ||
  product_type === SolutionProductType.ProductTypeTerm

const isFib = ({ product_type }: ProductBenefitItem) =>
  product_type === ProtectionProductType.ProductTypeFamilyIncomeBenefit

const isWol = ({ product_type }: ProductBenefitItem) => product_type === ProtectionProductType.ProductTypeWholeOfLife

const isIp = ({ product_type }: ProductBenefitItem) =>
  product_type === ProtectionProductType.ProductTypeIncomeProtection

const isOther = ({ product_type }: ProductBenefitItem) =>
  product_type === ProtectionProductType.ProductTypeMortgagePaymentProtection ||
  product_type === ProtectionProductType.ProductTypePmi ||
  product_type === ProtectionProductType.ProductTypeMortgageProtection ||
  product_type === ProtectionProductType.ProductTypeOther ||
  product_type === ProtectionProductType.ProductTypeSickPay

const isPmi = ({ product_type }: ProductBenefitItem) =>
  product_type === ProtectionProductType.ProductTypePrivateMedicalInsurance

const addProductTypeToArray = (
  array: (
    | SolutionProductBenefitItem
    | SolutionProductBenefitItemPmi
    | SolutionProductBenefitItemMetLife
    | SolutionProductBenefitGroupItem
    | ProtectionProductBenefitItem
    | BusinessProtectionPmiProductBenefitItem
    | ProductBenefit
    | SolutionProductBenefitItemPrivateMedicalInsurance
  )[],
  productType: string,
) => {
  return array.map((item) => ({ ...item, product_type: productType }))
}

// Convert from the new nested API structure to the old flat array of benefits
export function flattenProductBenefits<T extends SolutionProductBenefit | ProductBenefit>(
  benefits: T,
): T extends SolutionProductBenefit ? SolutionProductBenefitItem[] : ProductBenefitItem[] {
  return [
    ...(benefits.product_type_term || []),
    ...(benefits.product_type_family_income_benefit || []),
    ...(benefits.product_type_whole_of_life || []),
    ...(benefits.product_type_income_protection || []),
    ...(benefits.product_type_other || []),
    // The following lines are to re add the product_type field into the product benefit, because unlike protection products,
    // this field is not present in the BE for business protection products. These product_types show up as tags under `benefits`
    // on the protection products page
    ...addProductTypeToArray(
      benefits.product_type_child_accident_sickness || [],
      ProtectionProductType.ProductTypeChildAccidentSickness,
    ),
    ...addProductTypeToArray(
      benefits.product_type_core_accident_sickness || [],
      ProtectionProductType.ProductTypeCoreAccidentSickness,
    ),
    ...addProductTypeToArray(
      benefits.product_type_sports_lifestyle || [],
      ProtectionProductType.ProductTypeSportsLifestyle,
    ),
    ...addProductTypeToArray(
      benefits.product_type_specialist_healthcare_cover || [],
      ProtectionProductType.ProductTypeSpecialistHealthcareCover,
    ),
    ...addProductTypeToArray(
      benefits.product_type_private_medical_insurance || [],
      ProtectionProductType.ProductTypePrivateMedicalInsurance,
    ),
    ...addProductTypeToArray(
      benefits.product_type_group_life || [],
      BusinessProtectionBenefitOptions.ProductTypeGroupLife,
    ),
    ...addProductTypeToArray(
      benefits.product_type_group_pmi || [],
      BusinessProtectionBenefitOptions.ProductTypeGroupPmi,
    ),
    ...addProductTypeToArray(
      benefits.product_type_group_private_medical_insurance || [],
      BusinessProtectionBenefitOptions.ProductTypeGroupPrivateMedicalInsurance,
    ),
    ...addProductTypeToArray(
      benefits.product_type_shareholder_protection || [],
      BusinessProtectionBenefitOptions.ProductTypeShareholderProtection,
    ),
    ...addProductTypeToArray(
      benefits.product_type_group_asu || [],
      BusinessProtectionBenefitOptions.ProductTypeGroupAsu,
    ),
    ...addProductTypeToArray(
      benefits.product_type_group_cic || [],
      BusinessProtectionBenefitOptions.ProductTypeGroupCic,
    ),
    ...addProductTypeToArray(benefits.product_type_group_ip || [], BusinessProtectionBenefitOptions.ProductTypeGroupIp),
    ...addProductTypeToArray(
      benefits.product_type_relevant_life || [],
      BusinessProtectionBenefitOptions.ProductTypeRelevantLife,
    ),
    ...addProductTypeToArray(benefits.product_key_man || [], BusinessProtectionBenefitOptions.ProductKeyMan),
    ...addProductTypeToArray(benefits.product_type_pmi || [], BusinessProtectionBenefitOptions.ProductTypePmi),
  ] as T extends SolutionProductBenefit ? SolutionProductBenefitItem[] : ProductBenefitItem[]
}

export const nestProductBenefits = (benefits: ProductBenefitItem[]): ProductBenefitInput => {
  const term = benefits.filter(({ product_type }) => isTerm(product_type))
  const fib = benefits.filter(isFib)
  const ip = benefits.filter(isIp)
  const other = benefits.filter(isOther)
  const wol = benefits.filter(isWol)
  const pmi = benefits.filter(isPmi)
  // Remove non-applicable fields
  const fields = ['cover_amount_depreciation_rate']
  const otherNoDepr = other.map((benefit) => omitProperties(benefit, fields) as ProductBenefitItem)

  return {
    product_type_term: term || [],
    product_type_family_income_benefit: fib || [],
    product_type_whole_of_life: wol || [],
    product_type_income_protection: ip || [],
    product_type_other: otherNoDepr || [],
    product_type_private_medical_insurance: pmi || [],
  }
}

export const createTagLabelFull = (
  benefit: SolutionProductBenefitItem | ProductBenefitItem,
  formatMessage: (id: string) => string,
) => {
  const { product_type } = benefit

  // Product types enums don't map to their translations 1:1. We need to check if the deprecation
  // amount is greater than 0, because that's what determines whether it is decreasing or level term
  let productTypeKey = productTypeMap[product_type!]
  if (isTerm(product_type)) {
    const { cover_amount_depreciation_rate } = benefit
    const isDecreasing = (cover_amount_depreciation_rate || 0) > 0
    productTypeKey = isDecreasing ? 'protection.common.decreasingTermAssurance' : 'protection.common.levelTermAssurance'
  }

  return productTypeKey ? formatMessage(productTypeKey) : undefined
}

const isCurrent = (item?: ClientIncome) => item?.is_current

export const findEmploymentItemToUpdate = (client: Client) => {
  const { income_and_employment } = client

  // By default, if no employment items exist, we'll have added a new,
  // empty one at the zero'th index
  let indexToUpdate = 0
  const currentEmploymentIndex = income_and_employment?.findIndex(isCurrent) ?? -1

  // If we have some current employment, we want to edit that item
  if (income_and_employment && currentEmploymentIndex >= 0) {
    indexToUpdate = currentEmploymentIndex
  }
  // If we have no current employment, we want to edit the new item appended to that list
  else if (income_and_employment && currentEmploymentIndex < 0) {
    indexToUpdate = income_and_employment?.length
  }

  return indexToUpdate
}

export const createTagLabel = (
  benefit: SolutionProductBenefitItem | ProductBenefitItem | SolutionProductBenefitItemMetLife,
  formatMessage: ReturnType<typeof useFormatMessage>,
) => {
  const product_type = 'product_type' in benefit ? benefit.product_type : undefined
  const payout_type = 'payout_type' in benefit ? benefit.payout_type : undefined
  const units_of_cover = 'units_of_cover' in benefit ? benefit.units_of_cover : undefined
  const underwriting_level = 'underwriting_level' in benefit ? benefit.underwriting_level : undefined

  const payoutTypeKey = payout_type ? payoutTypeMapShorthand[payout_type] : ''
  const unitsOfCoverKey = units_of_cover ? coverUnitsMap[units_of_cover] : ''
  const underwritingLevelKey = underwriting_level ? underwritingLevelMap[underwriting_level] : ''

  // Product types enums don't map to their translations 1:1. We need to check if the deprecation
  // amount is greater than 0, because that's what determines whether it is decreasing or level term
  let productTypeKey = productTypeMapShorthand[product_type!]

  if (isTerm(product_type)) {
    const cover_amount_depreciation_rate =
      'cover_amount_depreciation_rate' in benefit ? benefit.cover_amount_depreciation_rate : undefined
    const isDecreasing = (cover_amount_depreciation_rate || 0) > 0
    productTypeKey = isDecreasing ? 'protection.common.decrTerm' : 'protection.common.levelTerm'
  }

  if (product_type === SolutionProductType.ProductTypeWholeOfLife) {
    productTypeKey = 'protection.common.wholeOfLife'
  }

  const coverAmount = 'cover_amount' in benefit ? formatAsCurrency(benefit.cover_amount) : null
  const coverAmountLife = 'cover_amount_life' in benefit ? formatAsCurrency(benefit.cover_amount_life) : null
  const coverAmountCic = 'cover_amount_cic' in benefit ? formatAsCurrency(benefit.cover_amount_cic) : null
  const coverAmountLifeOrEarlierCic =
    'cover_amount_life_or_earlier_cic' in benefit ? formatAsCurrency(benefit.cover_amount_life_or_earlier_cic) : null
  const coverAmountLifeLabel = formatMessage('protection.common.lifeCover')
  const coverAmountCicLabel = formatMessage('protection.common.cicCover')
  const coverAmountLifeOrEarlierCicLabel = formatMessage('protection.common.lifeOrEarlierCicCover')
  const productTypeLabel = productTypeKey ? formatMessage(productTypeKey) : ''
  const payoutTypeLabel = payoutTypeKey ? ` ${formatMessage(payoutTypeKey)}` : ''
  const coverUnitsTypeLabel = unitsOfCoverKey ? ` ${formatMessage(unitsOfCoverKey)}` : ''
  const underWritingLevelLabel = underwritingLevelKey ? ` ${formatMessage(underwritingLevelKey)}` : ''
  const coverAmountLabel = coverAmount
    ? `${formatMessage(getPayoutFrequencyMsgId(product_type), { amount: coverAmount })}`
    : ''

  if ((coverAmountLife || coverAmountCic || coverAmountLifeOrEarlierCic) && productTypeLabel) {
    const labelParts = [
      coverAmountLife ? `${coverAmountLifeLabel} ${coverAmountLife}` : '',
      coverAmountCic ? `${coverAmountCicLabel} ${coverAmountCic}` : '',
      coverAmountLifeOrEarlierCic ? `${coverAmountLifeOrEarlierCicLabel} ${coverAmountLifeOrEarlierCic}` : '',
    ].filter(Boolean)

    const formattedLabel = labelParts.join('\n')

    return `${productTypeLabel}: ${formattedLabel}`
  } else if (coverUnitsTypeLabel || payoutTypeLabel || coverAmountLabel || underWritingLevelLabel) {
    return `${productTypeLabel}:${coverUnitsTypeLabel}${payoutTypeLabel}${coverAmountLabel}${underWritingLevelLabel}`
  }

  return `${productTypeLabel}`
}

export const getPayoutFrequencyMsgId = (productType?: Maybe<ProtectionProductType | SolutionProductType>) => {
  const messagePrefix = 'generic'
  switch (productType) {
    case ProtectionProductType.ProductTypeDecreasingTerm:
    case ProtectionProductType.ProductTypeLevelTerm:
    case ProtectionProductType.ProductTypeTerm:
    case ProtectionProductType.ProductTypeWholeOfLife:
      return `${messagePrefix}.slashLumpSum`
    case ProtectionProductType.ProductTypeFamilyIncomeBenefit:
      return `${messagePrefix}.slashYear`
    case ProtectionProductType.ProductTypeIncomeProtection:
      return `${messagePrefix}.slashMo`
    default:
      // lump sum because it's the one that doesn't include any postfixes
      return `${messagePrefix}.slashLumpSum`
  }
}

// We use a generic so that we can use the same type T as the second argument to the reducer
export function createUniqueBenefitTags<T extends SolutionProductBenefitItem | ProductBenefitItem>(benefits: T[]): T[] {
  // Javascript's 'Set' does not allow for overriding the built-in compare method, so we have
  // to use a brute force strategy instead. It's memoized so shouldn't impact performance too much
  // https://stackoverflow.com/questions/29759480/how-to-customize-object-equality-for-javascript-set
  const uniqueBenefits = benefits.reduce(
    (acc, benefit) => {
      const hasSimilarBenefit = !!acc.find((existingBenefit) => areBenefitsSimilar(benefit, existingBenefit))
      return hasSimilarBenefit ? acc : [...acc, benefit]
    },
    [] as unknown as T[],
  )

  return uniqueBenefits
}

export const areBenefitsSimilar = (
  benefit1: SolutionProductBenefitItem | ProductBenefitItem,
  benefit2: SolutionProductBenefitItem | ProductBenefitItem,
) => {
  const sameProductType = benefit1.product_type === benefit2.product_type
  const samePayoutType = benefit1.payout_type === benefit2.payout_type

  // If both policies are level (i.e. have no deprecation rate) then we assume they
  // are similar. However if they are both decreasing, we only care if they have a
  // rate greater than zero, rather than if the rates are the same

  const bothLevel = !benefit1.cover_amount_depreciation_rate && !benefit2.cover_amount_depreciation_rate

  // We can assert with '!' because its the inverse of the above assumption
  const bothDecreasing = benefit1.cover_amount_depreciation_rate! > 0 && benefit2.cover_amount_depreciation_rate! > 0

  const oneDecreasingOneLevel =
    (!benefit1.cover_amount_depreciation_rate && !!benefit2.cover_amount_depreciation_rate) ||
    (!!benefit1.cover_amount_depreciation_rate && !benefit2.cover_amount_depreciation_rate)

  return sameProductType && samePayoutType && (bothLevel || bothDecreasing || !oneDecreasingOneLevel)
}

export const createTagColour = (benefit: SolutionProductBenefitItem | ProductBenefitItem) => {
  const { product_type, payout_type } = benefit
  let colourId = ColourId.BaseLightColor

  // Colour maps for 'term' assurance
  if (payout_type && isTerm(product_type)) {
    const { cover_amount_depreciation_rate } = benefit
    const isDecreasing = (cover_amount_depreciation_rate || 0) > 0
    colourId = isDecreasing ? decreasingColorMap[payout_type] : levelColorMap[payout_type]
  }

  // Colour maps for 'family income benefit'
  if (payout_type && product_type === SolutionProductType.ProductTypeFamilyIncomeBenefit) {
    return fibColorMap[payout_type]
  }

  if (product_type === SolutionProductType.ProductTypeIncomeProtection) {
    return ColourId.DarkYellow
  }

  return colourId
}

const decreasingColorMap: Record<PayoutType, ColourId> = {
  [PayoutType.PayoutTypeInvalid]: ColourId.BaseLightColor,
  [PayoutType.PayoutTypeLife]: ColourId.LightPurple,
  [PayoutType.PayoutTypeLifeOrEarlierCic]: ColourId.LightBlue,
  [PayoutType.PayoutTypeCic]: ColourId.LightRed,
}

const levelColorMap: Record<PayoutType, ColourId> = {
  [PayoutType.PayoutTypeInvalid]: ColourId.BaseLightColor,
  [PayoutType.PayoutTypeLife]: ColourId.Pink,
  [PayoutType.PayoutTypeLifeOrEarlierCic]: ColourId.Orange,
  [PayoutType.PayoutTypeCic]: ColourId.Periwinkle,
}

const fibColorMap: Record<PayoutType, ColourId> = {
  [PayoutType.PayoutTypeInvalid]: ColourId.BaseLightColor,
  [PayoutType.PayoutTypeLife]: ColourId.SkyBlue,
  [PayoutType.PayoutTypeLifeOrEarlierCic]: ColourId.Orange,
  [PayoutType.PayoutTypeCic]: ColourId.Purple,
}
