import React, { useCallback, useMemo, useRef } from 'react'
import { isValid, parse } from 'date-fns'
import { Field, FieldProps as FieldPropsFF, FieldRenderProps, FormSpy, FormSpyRenderProps } from 'react-final-form'

import { composeValidators, showErrorOnChange } from '../mui-rff'
import {
  DateField,
  DateFieldProps,
  OBFUSCATED_VALUE_FORMAT,
  OBFUSCATED_VALUE_PARSE,
  VALUE_FORMAT,
  VALUE_PARSE,
  useFormatMessage,
} from '@acre/design-system'

export interface DateFieldFFProps<
  FieldValue,
  RP extends FieldRenderProps<FieldValue, T, InputValue>,
  T extends HTMLElement,
  InputValue,
> extends DateFieldProps {
  name: string
  FieldProps?: Omit<FieldPropsFF<FieldValue, RP, T, InputValue>, 'name' | 'render'>
}

const DateFieldFF = React.forwardRef(function DateFieldFF<
  FieldValue,
  RP extends FieldRenderProps<FieldValue, T, InputValue>,
  T extends HTMLElement,
  InputValue,
>(
  {
    name,
    helperText,
    FieldProps,
    parseValue = VALUE_PARSE,
    formatValue = VALUE_FORMAT,
    parseObfuscatedValue = OBFUSCATED_VALUE_PARSE,
    formatObfuscatedValue = OBFUSCATED_VALUE_FORMAT,
    ...rest
  }: DateFieldFFProps<FieldValue, RP, T, InputValue>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const { enableProtected } = rest

  const formatMessage = useFormatMessage()

  const nativeErrorRef = useRef<string | undefined>()

  const validate = () => nativeErrorRef.current

  const field = useMemo(
    () => (
      <Field<Date | string | null>
        name={name}
        render={({ input: { name, value = null, onChange, onBlur, onFocus, ...inputRest }, meta }) => {
          const isError = showErrorOnChange({ meta })

          let errorText: string | undefined = undefined

          if (isError) {
            errorText =
              typeof meta.error === 'string'
                ? meta.error
                : formatMessage(meta.error.message, { ...meta.error.values, label: rest.label })
          }

          let _value = value

          if (!rest.enableProtected && typeof value === 'string') {
            _value = value ? parse(value, parseValue, new Date()) : null
          }

          return (
            <DateField
              {...rest}
              ref={ref}
              inputProps={inputRest}
              name={name}
              value={(_value as Date) || null}
              onChange={(value) => {
                if (isValid(value) || !value) {
                  nativeErrorRef.current = undefined
                }

                onChange(value ?? null)
              }}
              onBlur={onBlur}
              onFocus={onFocus}
              onError={(value) => {
                nativeErrorRef.current = _value && value === 'invalidDate' ? 'Invalid date' : undefined
              }}
              helperText={helperText}
              error={errorText}
              parseValue={parseValue}
              formatValue={formatValue}
              parseObfuscatedValue={parseObfuscatedValue}
              formatObfuscatedValue={formatObfuscatedValue}
            />
          )
        }}
        {...FieldProps}
        validate={FieldProps?.validate ? composeValidators(FieldProps.validate, validate) : validate}
      />
    ),
    [
      FieldProps,
      formatMessage,
      formatObfuscatedValue,
      formatValue,
      helperText,
      name,
      parseObfuscatedValue,
      parseValue,
      ref,
      rest,
    ],
  )

  const countRef = useRef(0)
  const hasSubmittedRef = useRef(false)

  const handleFormSpyRender = useCallback(
    (props: FormSpyRenderProps) => {
      if (props.submitting) {
        hasSubmittedRef.current = true
      }

      if (props.touched && hasSubmittedRef.current && name in props.touched && !props.submitting) {
        hasSubmittedRef.current = false
        countRef.current++
      }

      return <React.Fragment key={countRef.current}>{field}</React.Fragment>
    },
    [field, name],
  )

  return (
    <>
      {enableProtected ? (
        // This causes the field to re-render when the form successfully submits. This is needed to reset protected fields to their original state.
        // It relies on the onSubmit prop passed to Form being async. This will always be the case but is worth mentioning.
        <FormSpy subscription={{ submitting: true, touched: true }} render={handleFormSpyRender} />
      ) : (
        field
      )}
    </>
  )
})

export default DateFieldFF
