import { useMemo } from 'react'
import {
  ComponentMapBase,
  ComponentSchemaPropsMapBase,
  FormComponentSchema,
  FormSchema,
  InferPropsMap,
} from '../types/schema'
import type { AnyObjectSchema, InferType } from 'yup'
import type { FieldValues, DefaultValues } from 'react-hook-form'

const indexBlocks = <
  TFieldValues extends FieldValues,
  CPropsMap extends ComponentSchemaPropsMapBase,
  TFormComponentSchema extends FormComponentSchema = FormComponentSchema<TFieldValues, CPropsMap>,
>(
  blocks: TFormComponentSchema[],
) => {
  let index: Record<string, TFormComponentSchema> = {}

  for (const block of blocks) {
    if (!block.components) {
      index[block.name] = block
      continue
    }

    index = Object.assign(index, indexBlocks(block.components))
  }

  return index
}

export default function useParseSchema<
  TValidationSchema extends AnyObjectSchema,
  TVisibilitySchema extends AnyObjectSchema,
  TFieldValues extends FieldValues = InferType<TValidationSchema>,
  CMap extends ComponentMapBase = ComponentMapBase,
  CPropsMap extends ComponentSchemaPropsMapBase = InferPropsMap<CMap>,
  TFormComponentSchema extends FormComponentSchema = FormComponentSchema<TFieldValues, CPropsMap>,
>(schema: Omit<FormSchema<TValidationSchema, TVisibilitySchema, CMap>, 'validationSchema'>) {
  return useMemo(() => {
    const { blocks, transforms, ...formProps } = schema

    const blockIndex = indexBlocks<TFormComponentSchema, CPropsMap>(blocks)

    let defaultValues = { ...formProps.defaultValues } as Record<keyof DefaultValues<TFieldValues>, unknown>

    if (defaultValues && transforms) {
      defaultValues = Object.entries(defaultValues).reduce(
        (acc, [key, value]: [keyof DefaultValues<TFieldValues>, unknown]) => {
          const fieldType = blockIndex[key].type

          if (fieldType in transforms) {
            acc[key] = transforms[fieldType]?.(value, 'in')
          } else {
            acc[key] = value
          }

          return acc
        },
        defaultValues,
      )
    }

    return {
      blocks,
      blockIndex,
      formProps: {
        ...formProps,
        defaultValues: defaultValues as DefaultValues<TFieldValues>,
      },
    }
  }, [schema])
}
