import React, { useCallback, useRef, useState } from 'react'
import { unstable_composeClasses } from '@mui/material'
import { DateField as MuiDateField, DateFieldProps as MuiDateFieldProps } from '@mui/x-date-pickers-pro'
import classnames from 'classnames'
import { addYears, format as dateFnsFormat, format, isValid, parse } from 'date-fns'

import DisplayField from '../DisplayField/DisplayField'
import { ExtendField, FieldProps } from '../Field/Field.types'
import useFieldSlotProps from '../Field/hooks/useFieldSlotProps'
import TextField from '../TextField/TextField'
import { useProtectedTextField } from '../TextField/useProtectedTextField'
import { getDateFieldUtilityClass } from './dateFieldClasses'

export const VALUE_PARSE = 'yyyy-MM-dd'
export const VALUE_FORMAT = 'dd / MM / yyyy'
export const OBFUSCATED_VALUE_PARSE = 'yyyy-**-**'
export const OBFUSCATED_VALUE_FORMAT = '** / ** / yyyy'
export const HIDE_DAY_VALUE_PARSE = 'yyyy-MM'
export const HIDE_DAY_DISPLAY_FORMAT = 'MM/yyyy'

interface MuiDateFieldPropsWithRequiredName extends FieldProps, ExtendField<MuiDateFieldProps<Date>> {}

export interface DateFieldProps
  extends Omit<MuiDateFieldPropsWithRequiredName, 'inputRef' | 'onChange' | 'value' | 'defaultValue'> {
  defaultValue?: string | Date
  value?: string | Date
  enableProtected?: boolean
  loadData?: () => Promise<string>
  onChange?: (value?: string, context?: any) => void
  parseValue?: string
  formatValue?: string
  parseObfuscatedValue?: string
  formatObfuscatedValue?: string
  hideControls?: boolean
  hideDay?: boolean
  hideDayParseValue?: string
}

const useUtilityClasses = (ownerState: Partial<DateFieldProps>) => {
  const slots = {
    root: ['root', ownerState.layout || 'row', ownerState.size || 'medium'],
    field: ['field'],
    label: ['label'],
    input: ['input'],
    helperText: ['helperText'],
    errorText: ['errorText'],
    displayField: ['displayField'],
  }

  return unstable_composeClasses(slots, getDateFieldUtilityClass, ownerState.classes)
}

const maxDate = addYears(new Date(), 200)

const _DateField = React.forwardRef(function DateField(props: DateFieldProps, ref: React.ForwardedRef<HTMLDivElement>) {
  const {
    className,
    classes,
    enableProtected,
    name,
    value,
    layout = 'row',
    size = 'medium',
    slotProps,
    loadData,
    hideDay,
    error,
    helperText,
    FormHelperTextProps,
    ErrorTextProps,
    InputLabelProps,
    parseValue = VALUE_PARSE,
    formatValue = VALUE_FORMAT,
    hideDayParseValue = HIDE_DAY_VALUE_PARSE,
    parseObfuscatedValue = OBFUSCATED_VALUE_PARSE,
    formatObfuscatedValue = OBFUSCATED_VALUE_FORMAT,
    hideControls = false,
    WrapperProps,
    ...rootProps
  } = props

  const slotClasses = useUtilityClasses({ classes, layout, size })

  const fieldRef = useRef<HTMLInputElement | null>(null)

  const inputRef = ref || fieldRef

  const color = props.color || 'primary'

  const { labelProps, helperTextProps, errorProps } = useFieldSlotProps(slotClasses, {
    ...props,
    FormHelperTextProps,
    ErrorTextProps,
    InputLabelProps,
  })

  const dateFieldProps: Omit<DateFieldProps, 'variant' | 'size'> = {
    ...rootProps,
    name,
    value,
    maxDate,
    color,
    error,
    format: hideDay ? HIDE_DAY_DISPLAY_FORMAT : rootProps.format,
    formatDensity: 'spacious',
    inputProps: {
      ...rootProps.inputProps,
      className: classnames(slotClasses.input),
    },
    slots: { textField: TextField },
    slotProps: {
      ...slotProps,
      textField: {
        ...slotProps?.textField,
        ['data-testid']: props['data-testid'],
        className: classnames(slotClasses.field),
        sx: {
          flex: 1,
        },
        layout,
        // @ts-expect-error Waiting for v7
        size,
        // @ts-expect-error Waiting for v7
        // - also, this must pass an empty string if there is no error, otherwise the date field will automatically pass false to the TextField
        error: error ?? '',
        helperText,
        InputLabelProps: labelProps,
        FormHelperTextProps: helperTextProps,
        ErrorTextProps: errorProps,
        WrapperProps: {
          // @ts-expect-error Waiting for v7
          ...slotProps?.textField?.WrapperProps,
          ...WrapperProps,
          className: classnames(slotClasses.root, className),
        },
      },
    },
  }

  const onChange = dateFieldProps.onChange

  const handleUnprotectedChange = useCallback<Required<MuiDateFieldProps<Date>>['onChange']>(
    (value, context) => {
      const valueToFormat = hideDay ? hideDayParseValue : parseValue
      const _val = value && isValid(value) ? dateFnsFormat(value, valueToFormat) : undefined

      onChange?.(_val, context)
    },
    [hideDay, hideDayParseValue, onChange, parseValue],
  )

  const valueRef = useRef<Date | null>()
  const defaultValueRef = useRef<Date | null>()

  if (enableProtected) {
    return (
      <ProtectedDateField
        {...dateFieldProps}
        loadData={loadData}
        ref={inputRef}
        parseValue={parseValue}
        formatValue={formatValue}
        hideDayParseValue={hideDayParseValue}
        parseObfuscatedValue={parseObfuscatedValue}
        formatObfuscatedValue={formatObfuscatedValue}
        hideControls={hideControls}
        helperText={helperText}
        error={error}
        layout={layout}
        size={size}
        InputLabelProps={labelProps}
        FormHelperTextProps={helperTextProps}
        ErrorTextProps={errorProps}
      />
    )
  }

  if (typeof props.defaultValue === 'string') {
    if (!defaultValueRef.current && !props.enableProtected) {
      if (props.defaultValue) {
        const valueToFormat = props.hideDay ? hideDayParseValue : parseValue
        defaultValueRef.current = parse(props.defaultValue, valueToFormat, new Date())
      } else {
        defaultValueRef.current = undefined
      }
    }
  } else {
    defaultValueRef.current = props.defaultValue
  }

  if (typeof value === 'string') {
    if (!valueRef.current && !props.enableProtected) {
      const valueToFormat = props.hideDay ? hideDayParseValue : parseValue
      valueRef.current = value ? parse(value, valueToFormat, new Date()) : null
    }
  } else {
    valueRef.current = value
  }

  return (
    <MuiDateField
      {...dateFieldProps}
      inputRef={inputRef}
      onChange={handleUnprotectedChange}
      defaultValue={defaultValueRef.current}
      value={defaultValueRef.current ? undefined : valueRef.current}
    />
  )
})

export interface ProtectedDateFieldProps extends Omit<DateFieldProps, 'inputRef' | 'enableProtected'> {}

const _ProtectedDateField = React.forwardRef(function ProtectedDateField(
  props: ProtectedDateFieldProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const {
    value,
    name,
    classes,
    className,
    loadData,
    onChange,
    label,
    helperText,
    error,
    layout,
    size,
    slotProps,
    hideDay,
    hideDayParseValue = HIDE_DAY_VALUE_PARSE,
    InputLabelProps,
    FormHelperTextProps,
    ErrorTextProps,
    parseValue = VALUE_PARSE,
    formatValue = VALUE_FORMAT,
    parseObfuscatedValue = OBFUSCATED_VALUE_PARSE,
    formatObfuscatedValue = OBFUSCATED_VALUE_FORMAT,
    hideControls = false,
    ...rootProps
  } = props

  const slotClasses = useUtilityClasses({ classes, layout, size })

  const initialValue = (value || props.defaultValue) as Date

  const [loading, setLoading] = useState(false)

  const handleLoadData = useCallback(async () => {
    if (!loadData) {
      return null
    }

    setLoading(true)

    const result = await loadData()

    setLoading(false)

    if (!result) {
      return null
    }

    if (typeof result === 'string') {
      return parse(result, parseValue, new Date())
    }

    return result
  }, [loadData, parseValue])

  const { isProtected, isEditMode, controlButtons, protectedValue, setProtectedValue } = useProtectedTextField<Date>({
    name,
    inputRef: ref as React.MutableRefObject<HTMLInputElement>,
    initialValue,
    loadData: handleLoadData,
    disabled: rootProps.disabled,
    hideControls,
  })

  const handleChange = useCallback<Required<MuiDateFieldProps<Date>>['onChange']>(
    (value, context) => {
      if (value) {
        setProtectedValue(value)
      }

      if (onChange) {
        if (!value && typeof value === 'string') {
          return onChange(value, context)
        }

        const _val =
          value && isValid(value) ? dateFnsFormat(value, hideDay ? hideDayParseValue : parseValue) : undefined

        onChange(_val, context)
      }
    },
    [hideDay, hideDayParseValue, onChange, parseValue, setProtectedValue],
  )

  const commonFieldProps = {
    name,
    label,
    helperText,
    error,
  }

  const inputSlotProps = {
    InputLabelProps,
    FormHelperTextProps,
    ErrorTextProps,
  }

  const isShowMode = !isEditMode && !isProtected

  if (isEditMode) {
    return (
      <MuiDateField
        {...rootProps}
        {...commonFieldProps}
        slotProps={{
          textField: {
            ...slotProps?.textField,
            ...inputSlotProps,
            placeholder: 'DD / MM / YYYY',
            // @ts-expect-error Waiting for v7
            size,
            layout,
          },
        }}
        // Below styles for added word spacing within the input
        // Need opinion from Alsal before uncommenting/removing
        // sx={(theme) => ({
        //   '& input': { textAlign: 'center', wordSpacing: '2rem' },
        //   [`${theme.breakpoints.down('md')}`]: { '& input': { wordSpacing: '1.5rem' } },
        //   [`${theme.breakpoints.down('sm')}`]: { '& input': { wordSpacing: 'unset' } },
        // })}
        inputRef={(instance: HTMLInputElement) => {
          // eslint-disable-next-line no-extra-semi
          ;(ref as React.MutableRefObject<HTMLInputElement>).current = instance
        }}
        value={(protectedValue as Date) || undefined}
        onChange={handleChange}
      />
    )
  }

  const displayValue = isShowMode ? protectedValue : initialValue
  let finalValue = ''

  if (!isShowMode && typeof displayValue === 'string') {
    let parsed: Date | undefined = undefined

    try {
      parsed = parse(displayValue, parseObfuscatedValue, new Date())

      if (!isValid(parsed)) {
        parsed = parse(displayValue, parseValue, new Date())
      }

      finalValue = parsed ? format(parsed, formatObfuscatedValue) : ''
    } catch (e) {
      console.error(e)
    }
  }

  if (isShowMode && displayValue instanceof Date) {
    finalValue = format(displayValue, formatValue)
  }

  return (
    <DisplayField
      {...commonFieldProps}
      {...inputSlotProps}
      className={classnames(slotClasses.root, className)}
      disabled={rootProps.disabled}
      value={finalValue}
      valueClassName={classnames(slotClasses.displayField)}
      controls={controlButtons}
      loading={loading}
      layout={layout}
      size={size}
    />
  )
})

export const ProtectedDateField = React.memo(_ProtectedDateField) as typeof _ProtectedDateField

const DateField = React.memo(_DateField) as typeof _DateField

export default DateField
