import React, { useCallback } from 'react'
import { Box, BoxProps } from '@mui/material'
import { FormApiProvider } from '../hooks/useFormApi'
import { VisibilitySchemaProvider } from '../hooks/useVisibilitySchema'
import type { AnyObjectSchema, InferType } from 'yup'
import {
  UseFormReturn,
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  UseFormProps,
  DefaultValues,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ValidationSchemaProvider } from '../hooks/useValidationSchema'
import type { ComponentMapBase, ComponentSchemaPropsMapBase, InferPropsMap } from '../types/schema'
import type { CancelHandler } from '../types/types'
import isValueDefined from './util/isValueDefined'

export interface FormProps<
  TValidationSchema extends AnyObjectSchema,
  TVisibilitySchema extends AnyObjectSchema | undefined = undefined,
  TFieldValues extends FieldValues = InferType<TValidationSchema>,
  CMap extends ComponentMapBase = ComponentMapBase,
  CPropsMap extends ComponentSchemaPropsMapBase = InferPropsMap<CMap>,
> extends Omit<UseFormProps<TFieldValues>, 'resolver' | 'defaultValues'>,
    Pick<BoxProps<'form'>, 'children'> {
  errorMode?: 'first' | 'all'
  submitMode?: 'allow' | 'block'
  validationSchema: TValidationSchema
  visibilitySchema?: TVisibilitySchema
  componentMap?: Partial<CMap>
  // TODO: implement - for now it's just here to capture the types
  componentDefaultProps?: Partial<CPropsMap>
  defaultValues: UseFormProps<TFieldValues>['defaultValues']
  formProps?: Omit<BoxProps<'form'>, 'children' | 'component' | 'noValidate' | 'autoComplete' | 'onSubmit'>
  onSubmit: SubmitHandler<TFieldValues>
  onError?: SubmitErrorHandler<TFieldValues>
  onCancel?: CancelHandler<TFieldValues>
  formApiRef?: React.MutableRefObject<UseFormReturn<TFieldValues> | undefined>
  formWrapper?: React.ElementType
  formWrapperProps?: Record<string, unknown>
  parseText?: (text: string) => string
}

function _Form<
  TValidationSchema extends AnyObjectSchema,
  TVisibilitySchema extends AnyObjectSchema,
  TFieldValues extends FieldValues = InferType<TValidationSchema>,
  CMap extends ComponentMapBase = ComponentMapBase,
>({
  errorMode = 'first',
  submitMode = 'allow',
  componentMap,
  validationSchema,
  visibilitySchema,
  formProps,
  onSubmit,
  onError,
  onCancel,
  children,
  formApiRef,
  formWrapper: FormWrapper = React.Fragment,
  formWrapperProps,
  parseText,
  ...props
}: FormProps<TValidationSchema, TVisibilitySchema, TFieldValues, CMap>) {
  const methods = useForm<TFieldValues>({
    criteriaMode: 'all',
    mode: 'all',
    ...props,
    resolver: yupResolver(validationSchema),
  })

  if (formApiRef && !formApiRef.current) {
    formApiRef.current = methods
  }

  const defaultValues = props.defaultValues

  const handleSubmit = useCallback<SubmitHandler<TFieldValues>>(
    (values, event) => {
      const _values = { ...values } as FieldValues

      for (const key in defaultValues) {
        if (isValueDefined((defaultValues as DefaultValues<TFieldValues>)[key]) && !isValueDefined(values[key])) {
          _values[key] = null
        }
      }

      return onSubmit(_values as TFieldValues, event)
    },
    [defaultValues, onSubmit],
  )

  if (!componentMap) {
    console.error('No component map defined')

    return <p style={{ textAlign: 'center' }}>Form Error: No component map defined</p>
  }

  return (
    <FormProvider {...methods}>
      <ValidationSchemaProvider value={validationSchema}>
        <VisibilitySchemaProvider value={visibilitySchema}>
          <FormApiProvider
            value={{
              errorMode,
              submitMode,
              componentMap,
              parseText,
              // Providers don't support generics, so we need to cast here
              onCancel: onCancel as CancelHandler<FieldValues>,
            }}
          >
            <FormWrapper {...formWrapperProps}>
              <Box
                {...formProps}
                noValidate
                name="form"
                component="form"
                autoComplete="off"
                onSubmit={methods.handleSubmit(handleSubmit, onError)}
              >
                {children}
              </Box>
            </FormWrapper>
          </FormApiProvider>
        </VisibilitySchemaProvider>
      </ValidationSchemaProvider>
    </FormProvider>
  )
}

const Form = React.memo(_Form) as typeof _Form

export default Form
