import React, { MouseEvent as ReactMouseEvent, ReactElement, useState } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'
import classNames from 'classnames'

import { testHandle, useFormatMessage } from '@acre/utils'

import withDisabled from '../../hoc/withDisabled'
import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import { getColourHex } from '../../utils/colourHex'
import { Variant } from '../../utils/constants'
import { getTextColourHex } from '../../utils/textColourHex'
import { ColourId, ItemCardData } from '../../utils/types'
import Card from '../Card'
import Checkbox from '../Checkbox'
import FieldListItem from '../FieldListItem'
import Tooltip from '../Tooltip'
import { Align } from '../FieldListItem/FieldListItem.styles'
import { CardTitle, Title } from './ColouredItemCard.styles'

type Props = {
  colourID: ColourId
  title?: string | (string | undefined)[] | null
  data?: ItemCardData[]
  id?: string
  cardOptions?: ReactElement
  fullHeight?: boolean
  defaultValue?: string
  onClick?: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void
  align?: Align
  variant?: Variant
  disabled?: boolean
  fontSize?: number
  extraPadding?: boolean
  shouldRenderTooltip?: boolean
  messagePrefix?: string
  shouldRenderCheckbox?: boolean
  handlePopulateDepositForField?: (checked: boolean) => void
}

const ColouredItemCard = ({
  id,
  colourID,
  title,
  data = [],
  cardOptions,
  fullHeight,
  defaultValue,
  onClick,
  align = 'left',
  variant = 'compactLeftAligned',
  disabled: disabledProp,
  fontSize = 12,
  extraPadding,
  shouldRenderTooltip = false,
  messagePrefix,
  shouldRenderCheckbox = false,
  handlePopulateDepositForField,
}: Props) => {
  const [checked, setChecked] = useState(false)
  const formatMessage = useFormatMessage()
  const theme = useTheme()
  const disabled = useFieldDisabledState(disabledProp)
  const alignOptions = Boolean(cardOptions)
  const colourHex = getColourHex(colourID)
  const textColourHex = colourID ? getTextColourHex(colourID) : undefined
  const isDefaultColour = colourID === ColourId.AcreBlue

  // We check if the user can interact with the card
  const isClickable = () => {
    if (disabled) {
      return false
    } else if (onClick) {
      return true
    }
  }

  const CardTitleElement = () => {
    const cardId = `${id}-Title`
    const createCardTitle = (header?: string) =>
      header ? (
        <Box display="flex">
          {shouldRenderCheckbox && (
            <Box mr={1}>
              <Checkbox
                shouldStopPropagation
                id={`${id}-ColouredItemCardCheckbox`}
                checked={checked}
                disabled={false}
                onChange={() => {
                  setChecked(!checked)
                  handlePopulateDepositForField && handlePopulateDepositForField(!checked)
                }}
              />
            </Box>
          )}
          <CardTitle
            hasOptions={Boolean(data.length)}
            data-pii
            color={colourHex}
            textColor={textColourHex}
            isDefaultColour={isDefaultColour}
            className={`${classNames({ disabled })}`}
            id={cardId}
            data-testid={testHandle(cardId)}
          >
            {header}
          </CardTitle>
        </Box>
      ) : (
        <div />
      )

    const cardTitle = () => {
      if (typeof title === 'string') {
        return createCardTitle(title) // Render HTML for string value
      } else if (Array.isArray(title)) {
        return (
          <>
            {title.map((item, index) =>
              item ? (
                <Box mt={index === 0 ? 0 : 1} key={index}>
                  {createCardTitle(item)}
                </Box>
              ) : (
                <div key={item} />
              ),
            )}
          </>
        )
      } else {
        // Handle other cases, if needed
        return <div />
      }
    }
    return (
      <Title
        className={alignOptions ? 'align-options' : undefined}
        marginBottom={data.length ? theme.spacers.size16 : '0px'}
      >
        <Box>{cardTitle()}</Box>
        {cardOptions && alignOptions ? cardOptions : null}
      </Title>
    )
  }

  const renderList = () => {
    if (data?.length)
      return data.reduce((acc, current, index) => {
        if (current.label || current.value) {
          acc.push(
            <FieldListItem
              key={`${id}-${index}`}
              id={current.id}
              value={current.value}
              label={current.label}
              defaultValue={defaultValue}
              leftWidth={current.leftWidth}
              smallFont
              fontSize={fontSize}
              border={false}
              boldValues
              variant={variant}
              align={align}
              // apply extra padding to all rows but the last
              extraPadding={index !== data.length - 1 && extraPadding}
            />,
          )
        }
        return acc
      }, [] as ReactElement[])
  }

  return (
    <Tooltip
      placement="bottom"
      content={messagePrefix && formatMessage(`${messagePrefix}.deposits.tooltipText`)}
      trigger="mouseenter focus"
      hide={!shouldRenderTooltip}
    >
      <Card
        id={id}
        fullHeight={fullHeight}
        clickable={isClickable()}
        colourID={colourID}
        onClick={onClick}
        padding={theme.spacers.size16}
        disabled={disabled}
      >
        <CardTitleElement />
        {renderList()}
      </Card>
    </Tooltip>
  )
}

export default withDisabled(ColouredItemCard)
