import { Button } from '@mui/material'
import React from 'react'
import type { ArrayPath, FieldArrayPath, FieldArrayWithId, FieldValues } from 'react-hook-form'
import { useFieldArrayComponent } from '@acre/forms'
import FieldSet from '../../common/FieldSet'

export interface FieldArrayRenderProps<TFieldValues extends FieldValues> {
  field: FieldArrayWithId<TFieldValues, ArrayPath<TFieldValues>, 'id'>
  name: FieldArrayPath<TFieldValues>
  index: number
  handleAddClick: () => void
  handleRemoveClick: (index: number) => void
}

export type FieldArrayRenderFn<TFieldValues extends FieldValues> = (
  props: FieldArrayRenderProps<TFieldValues>,
) => React.ReactNode

export interface FieldArrayRHFProps<TFieldValues extends FieldValues = FieldValues> {
  name: FieldArrayPath<TFieldValues>
  highlight?: boolean
  render: FieldArrayRenderFn<TFieldValues>
}

// TODO: Figure out how to make this configurable

const _FieldArrayRHF = <TFieldValues extends FieldValues>({
  name,
  highlight = false,
  render,
}: FieldArrayRHFProps<TFieldValues>) => {
  // TODO: pass render to useFieldArrayComponent to get simpler curried function out
  const { fields, handleAddClick, handleRemoveClick } = useFieldArrayComponent<TFieldValues>({ name })

  return (
    <>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <FieldSet highlight={highlight}>
            {/* NOTE: this is too much boilerplate */ render({ name, field, index, handleAddClick, handleRemoveClick })}
          </FieldSet>

          {index !== 0 && (
            <Button size="small" onClick={handleRemoveClick(index)}>
              - remove address
            </Button>
          )}

          {fields.length - 1 === index && (
            <Button size="small" onClick={handleAddClick}>
              + Add previous address
            </Button>
          )}
        </React.Fragment>
      ))}
    </>
  )
}

const FieldArrayRHF = React.memo(_FieldArrayRHF) as typeof _FieldArrayRHF

export default FieldArrayRHF
