import React, { memo, useCallback, useEffect, useState } from 'react'

import { testHandle } from '@acre/utils'
import { LutherAddress } from '@acre/graphql'
import { AddressAndFieldUpdates, PropertyFieldUpdates } from '@acre/graphql'
import { Variant, withDisabled } from '@acre/design-system'

import { convertToLutherAddress, formatLutherAddress } from './AddressLookup.helpers'
import AddressManual from './AddressManual'
import Lookup from './Lookup'

type Props = {
  address: LutherAddress
  onChange: (address: LutherAddress) => any
  propertyFieldsUpdated?: (id: number, fields: PropertyFieldUpdates) => any
  label?: string
  showLookup?: boolean
  variant?: Variant
  fieldPrefix?: string
  disabled?: boolean
  isMissing?: boolean
}

const AddressLookup = withDisabled(
  ({
    address,
    label,
    onChange,
    propertyFieldsUpdated,
    fieldPrefix = '',
    showLookup = true,
    variant = 'default',
    disabled = false,
    isMissing,
  }: Props) => {
    const [isAddressLookupVisible, setIsAddressLookupVisible] = useState(showLookup)

    useEffect(() => {
      if (isAddressLookupVisible !== showLookup) {
        setIsAddressLookupVisible(showLookup)
      }
    }, [showLookup])

    const handleSetAddress = useCallback((newAddress: AddressAndFieldUpdates) => {
      onChange(convertToLutherAddress(newAddress))
      if (propertyFieldsUpdated && newAddress.field_updates) {
        propertyFieldsUpdated(newAddress.property_id || 0, newAddress.field_updates)
      }
      setIsAddressLookupVisible(false)
    }, [])

    const handleLookupClick = useCallback(() => setIsAddressLookupVisible(false), [])
    const handleAddressManualClick = useCallback(() => setIsAddressLookupVisible(true), [])

    return (
      <div data-testid={testHandle('AddressLookup')}>
        {isAddressLookupVisible ? (
          <Lookup
            label={label}
            onClick={handleLookupClick}
            onGetAddress={(newAddress) => handleSetAddress(newAddress)}
            variant={variant}
            disabled={disabled}
            isMissing={isMissing}
          />
        ) : (
          <AddressManual
            address={formatLutherAddress(address)}
            onChange={handleSetAddress}
            onClick={handleAddressManualClick}
            variant={variant}
            fieldPrefix={fieldPrefix}
            disabled={disabled}
            address1IsMissing={isMissing && !address?.address1}
            posttownIsMissing={isMissing && !address?.posttown}
            postcodeIsMissing={isMissing && !address?.postcode}
          />
        )}
      </div>
    )
  },
)
export default memo(AddressLookup)
