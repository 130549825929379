import { styled } from '@mui/material'
import { darken, transparentize } from 'polished'

import { disabledClassName } from '../../styles/disabled.styles'

export const Title = styled('div')<{ marginBottom: string }>(
  ({ marginBottom }) => `
  margin-bottom: ${marginBottom};

  &.align-options {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`,
)

export const CardTitle = styled('h2')<{
  color: string
  isDefaultColour: boolean
  hasOptions: boolean
  textColor?: string
}>(
  ({ theme, color, isDefaultColour, textColor }) => `
  font-size: 14px;
  display: flex;
  align-items: center;
  max-height: ${theme.spacing(3)};
  border-radius: ${theme.display.borderRadius};
  padding: 3px 10px;
  color: ${color};
  line-height: 1.6;

  width: fit-content;
  ${isDefaultColour && color && `background:  ${transparentize(theme.opacity.op90, color)}; color: ${color};`};
  ${!isDefaultColour && color && `background:  ${color}; color: ${textColor || darken(theme.opacity.op60, color)};`};
  ${disabledClassName(theme)};
`,
)

export const CardWrapper = styled('div')`
  &:hover {
    .disabled {
      cursor: not-allowed;
      pointer-events: auto;
    }
  }
`
export const GreyText = styled('span')(
  ({ theme }) => `
  color: ${theme.colours.baseMid};
`,
)
