import { CreateGeneralInsuranceProduct, GeneralInsuranceQuoteStage, Maybe } from '@acre/graphql'

export const postRecommendationGIProductStages = [
  GeneralInsuranceQuoteStage.Selected,
  GeneralInsuranceQuoteStage.ApplicationInProgress,
  GeneralInsuranceQuoteStage.Current,
]

export const isSourcedGIProduct = (giProduct: Maybe<CreateGeneralInsuranceProduct>) => {
  return giProduct?.details?.provider !== 'MANUAL'
}

// client structure required for request:
// clients: [
//     {
//       client_id: '30b52d99-e206-495b-99bd-11d8d1f4ea96',
//       details: {
//         date_of_birth: '1980-01-02',
//         first_name: 'Albertha',
//         last_name: 'Boyer',
//         title: 'Ms'
//       }
//     }
//   ]

// property structure required for request:
// property: {
//     details: {
//       address: {
//         address1: 'Yut Mobiles',
//         address2: '518 Stratford Road',
//         address3: 'Sparkhill',
//         country: 'GB',
//         county: 'West Midlands',
//         postcode: 'B11 4AJ',
//         posttown: 'Birmingham'
//       },
//       number_of_bedrooms: 2,
//       property_type: 'MAISONETTE',
//       year_property_built: 1990
//     },
//     property_id: '99de3d0d-c28d-4eac-9e06-18f7d55a23ba'
//   }
