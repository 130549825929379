import { FieldValues } from 'react-hook-form'
import useRevalidateOnDependencyChange, {
  UseRevalidateOnDependencyChangeProps,
} from '../../hooks/useRevalidateOnDependencyChange'

export type RevalidateOnChangeProps<T extends FieldValues> = UseRevalidateOnDependencyChangeProps<T>

const RevalidateOnDependencyChange = <T extends FieldValues>({ name, watch }: RevalidateOnChangeProps<T>) => {
  useRevalidateOnDependencyChange<T>({
    name,
    watch,
  })

  return null
}

export default RevalidateOnDependencyChange
