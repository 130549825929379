import { useMemo } from 'react'
import type { FieldValues, Path } from 'react-hook-form'
import type { AnyObjectSchema, AnySchema } from 'yup'
import safeReach from '../util/safeReach'
import useValidationSchema from './useValidationSchema'

export default function useFieldValidationSchema<T extends FieldValues, S extends AnyObjectSchema = AnyObjectSchema>(
  name: Path<T>,
) {
  const schema = useValidationSchema<S>()

  const fieldSchema = useMemo(() => schema && (safeReach<S>(schema, name) as AnySchema), [name, schema])

  return fieldSchema
}
