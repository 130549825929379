import React from 'react'
import { styled, unstable_composeClasses } from '@mui/material'
import classnames from 'classnames'
import { useFormContext, useFormApi } from '@acre/forms'
import { ButtonNewest as Button, ButtonNewestProps as ButtonProps } from '@acre/design-system'

import { getFormCancelActionUtilityClass } from './formCancelActionClasses'
import { useCallback } from 'react'

export interface FormCancelActionProps extends ButtonProps {}

const FormCancelActionRoot = styled(Button, {
  name: 'FormCancelAction',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})``

const useUtilityClasses = (ownerState: Partial<FormCancelActionProps>) => {
  const slots = {
    root: ['root'],
  }

  return unstable_composeClasses(slots, getFormCancelActionUtilityClass, ownerState.classes)
}

const _FormCancelAction = (props: FormCancelActionProps) => {
  const { className, classes, size = 'large', ...rootProps } = props

  const slotClasses = useUtilityClasses({ classes })

  const { reset, getValues } = useFormContext()
  const { onCancel } = useFormApi()

  const handleClick = useCallback(async () => {
    await onCancel?.(getValues())
    reset()
  }, [onCancel, getValues, reset])

  return (
    <FormCancelActionRoot
      {...rootProps}
      variant="secondary"
      type="reset"
      size={size}
      className={classnames(slotClasses.root, className)}
      onClick={handleClick}
    />
  )
}

const FormCancelAction = React.memo(_FormCancelAction) as typeof _FormCancelAction

export default FormCancelAction
