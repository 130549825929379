import { Note, UiReferenceSectionType } from '@acre/graphql'

import { FactFindSection } from '../../../pages/Clients/ClientDetails/ClientsProfile/useFactFindSection'

export const messagePrefix = 'cases.reviewMode.pageNotes'

export const UI_REFERENCE_AND_SECTIONNAME_MAP: { [key in string]: UiReferenceSectionType } = {
  // Fact find:
  mortgage: UiReferenceSectionType.CaseDetails,
  credit: UiReferenceSectionType.Credit,
  properties: UiReferenceSectionType.Property,
  income: UiReferenceSectionType.IncomeEmployment,
  outgoings: UiReferenceSectionType.Expenditure,
  conveyancing: UiReferenceSectionType.Conveyancing,
  protection: UiReferenceSectionType.Protection,
  'personal-info': UiReferenceSectionType.Clients,
  'id-verification': UiReferenceSectionType.IdVerification,
  // Protection:
  'protection-needs': UiReferenceSectionType.ProtectionPreQualification,
  'existing-protection': UiReferenceSectionType.ExistingProtection,
  'protection-requirements': UiReferenceSectionType.ProtectionRequirements,
  sourcing: UiReferenceSectionType.SourcingPreferences,
  products: UiReferenceSectionType.ProtectionProducts,
  recommendation: UiReferenceSectionType.ProtectionRecommendation,
}

export const getFilteredPageNotes = (notes: Note[], sectionName: string, clientId?: string, propertyId?: string) => {
  let filteredPageNotes: Note[] = []

  for (const key in UI_REFERENCE_AND_SECTIONNAME_MAP) {
    if (key === sectionName) {
      filteredPageNotes = notes.filter((note) => note.ui_reference?.section === UI_REFERENCE_AND_SECTIONNAME_MAP[key])
    }
  }

  // Filter notes specific to each client
  if (clientId) {
    filteredPageNotes = filteredPageNotes.filter((note) => note.client_id === clientId)
  }

  // Filter notes on property overview page
  if (sectionName === 'properties' && !propertyId) {
    filteredPageNotes = filteredPageNotes.filter((note) => !note.property_id)
  }

  // Filter notes specific to each property
  if (propertyId) {
    filteredPageNotes = filteredPageNotes.filter((note) => note.property_id === propertyId)
  }

  return filteredPageNotes
}

export const sortPageNotesByTime = (notes: Note[]) => {
  return notes.sort((a, b) => {
    const dateA = a.created_at ? new Date(a.created_at).getTime() : 0
    const dateB = b.created_at ? new Date(b.created_at).getTime() : 0
    return dateB - dateA
  })
}

export const isProtectionSourcingPage = (activePathname: string) => {
  const pathnames = [
    'details/protection-needs',
    'details/existing-protection',
    'details/protection-requirements',
    'protection/sourcing',
    'protection/products',
    'details/recommendation',
  ]
  return pathnames.some((pathname) => activePathname.includes(pathname))
}

export const getFactFindSection = (pathname: string) => {
  let section = ''
  const splitPathname = pathname.split('/')

  if (pathname.includes('/fact-find/')) {
    section = splitPathname[splitPathname.length - 1] as FactFindSection
  } else if (pathname.includes('/properties/edit/')) {
    section = 'properties' as FactFindSection
  } else if (isProtectionSourcingPage(pathname)) {
    section = splitPathname[splitPathname.length - 1] as FactFindSection
  }

  return section
}

export const noteBodyFormatter = (note: string) => {
  return note.split(/\n/g).map((text, index) => {
    if (!text) return <br />
    else return <p key={index}>{text}</p>
  })
}
